<template>
  <div class="flex flex-col h-full">
    <!-- HEADER -->
    <div class="h-[105px] grid grid-cols-2 gap-x-2 items-center px-8">
      <button
        class="cursor-pointer border-0 bg-transparent text-xl font-EffraM font-medium flex items-center justify-center gap-x-2 whitespace-nowrap w-full"
        :class="chatBy === 'PATIENT' ? 'text-black' : 'text-gray-400'"
        @click="chatByChange('PATIENT')"
      >
        Patients
        <div>
          <div
            class="h-6 w-6 rounded-full bg-rosDok-ultra font-EffraR text-lg text-white flex items-center justify-center"
          >
            {{ countPatient }}
          </div>
        </div>
      </button>
      <button
        class="cursor-pointer border-0 bg-transparent text-xl font-EffraM font-medium flex items-center justify-center gap-x-2 whitespace-nowrap w-full text-black"
        :class="chatBy === 'DOCTOR' ? 'text-black' : 'text-gray-400'"
        @click="chatByChange('DOCTOR')"
      >
        Confrères
        <div>
          <div
            class="h-6 w-6 rounded-full bg-rosDok-ultra font-EffraR text-lg text-white flex items-center justify-center"
          >
            {{ countDoctors }}
          </div>
        </div>
      </button>
    </div>
    <!-- Search Box -->
    <div
      class="border-0 border-b border-t border-solid border-gray-300 relative min-h-16 lg:min-h-20 flex items-center"
    >
      <input
        type="text"
        class="w-full py-3 px-1 rounded-cu border-0 text-base pr-12 h-full font-EffraR text-black "
        placeholder="Rechercher une conversation ..."
        v-model="search"
      />
      <span class="absolute right-0 top-0 px-3 h-full flex items-center">
        <i class="fas fa-search"></i>
      </span>
    </div>
    <!-- LIST SEARCH -->
    <div
      v-if="search.length > 2"
      class="border-0 border-b border-solid border-gray-200 divide-y divide-x-0 divide-gray-200 divide-solid"
    >
      <div class="py-4 px-1 font-EffraR text-black">
        Résultats de recherche (<b>{{ searchData.length }}</b
        >) :
      </div>
      <template v-if="searchData.length">
        <div
          v-for="user in searchData"
          :key="user._id"
          @click="createConversation(user)"
          class="h-[64px] px-2 flex items-center gap-x-2 cursor-pointer hover:bg-dokBlue-lighter"
        >
          <div
            class="h-[54px] w-[54px] bg-dokBlue-lighterr border border-solid border-gray-100 rounded-full overflow-hidden"
          >
            <img
              :src="$checkAvatar(user.image)"
              onerror="javascript:this.src='/no-avatar.png'"
              class="w-full h-full object-cover"
              :alt="`${user.firstName} ${user.lastName}`"
            />
          </div>
          <div class="flex flex-col flex-grow">
            <h1 class="text-base font-EffraM">
              {{ `${user.firstName} ${user.lastName}` }}
            </h1>
          </div>
        </div>
      </template>
    </div>
    <!-- INBOX TAB -->
    <div
      class="flex flex-col divide-y divide-x-0 divide-gray-200 divide-solid"
      v-if="!searchData.length"
    >
      <div
        v-for="conversation in sortConversations"
        :key="conversation._id"
        @click="onConversationClickedItem(conversation)"
        class="h-[64px] px-2 flex items-center gap-x-2 cursor-pointer"
        :class="
          false
            ? 'bg-dokBlue-lighter border-0 border-l-4 border-solid border-dokBlue-ultra'
            : 'hover:bg-dokBlue-lighter'
        "
      >
        <div
          class="h-[54px] w-[54px] bg-dokBlue-lighterr border border-solid border-gray-100 rounded-full overflow-hidden"
        >
          <img
            :src="$checkAvatar(conversation.image)"
            onerror="javascript:this.src='/no-avatar.png'"
            class="w-full h-full object-cover"
            :alt="conversation.title"
          />
        </div>
        <div class="flex flex-col flex-1">
          <h1 class="text-base font-EffraM">{{ conversation.title }}</h1>
          <div class="flex flex-row gap-x-2 justify-between">
            <!-- Last Message -->
            <span class="text-sm font-EffraR truncate">
              {{
                conversation.lastMessage && conversation.lastMessage.body
                  ? `${conversation.lastMessage.body}`
                  : "--"
              }}
            </span>
            <!-- Date -->
            <span class="text-sm font-EffraM text-gray-500">
              {{
                conversation.lastMessage && conversation.lastMessage.createdAt
                  ? moment()(conversation.lastMessage.createdAt).fromNow()
                  : "--"
              }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { orderBy, find } from "lodash";
import { mapActions } from "vuex";
import { EventBus } from "@/event-bus";
import moment from "moment";

export default {
  props: ["onConversationClicked", "isPatient", "chatBy"],
  data() {
    return {
      conversations: [],
      searchData: [],
      currentConversationId: null,
      search: "",
      searchLoader: false,
      loader: true
    };
  },
  watch: {
    search(newValue, oldValue) {
      if (newValue !== "" && newValue) this.onSearch(newValue);
      if (!newValue) this.searchData = [];
    }
  },
  computed: {
    countDoctors() {
      return this.conversations.filter(user => {
        return user.role === "DOCTOR";
      }).length;
    },
    countPatient() {
      return this.conversations.filter(user => {
        return user.role === "PATIENT";
      }).length;
    },
    sortConversations() {
      return this.conversations.filter(user => {
        return user.role === this.chatBy;
      });
    }
  },
  created() {
    this.getList();
  },
  mounted() {
    EventBus.$on("reloadLastMessage", this.getList);
  },
  methods: {
    ...mapActions({ getDoctors: "user/GET_ALL_DOCTORS" }),
    ...mapActions("client", ["SET_CONVERSATION", "GET_ITEM"]),
    async getList() {
      await this.$store.dispatch("chat/GET_CONVERSATIONS", {
        onData: this.onData
      });
    },
    async createConversation(user) {
      const findOne = find(
        this.conversations,
        data => data.title_id === user._id
      ); // Find Conversation  Exists

      if (findOne) {
        this.search = null; // Empty Data Search
        return this.onConversationClicked(findOne); // Open Conversation
      }

      const { ok, data } = await this.SET_CONVERSATION({ user: user._id });

      if (ok) {
        this.conversations.push(data); // Push New Conversation

        this.onConversationClicked(data); // Open Conversation
        this.search = null; // Empty Data Search
      }
    },
    async onSearch(value) {
      this.searchLoader = true;
      if (this.chatBy === "DOCTOR") {
        return this.getDoctors({
          onData: this.onDataSearch,
          params: { term: value }
        });
      } else {
        console.log("SEARCH FOR PATIENTS");
        return this.$store.dispatch("client/GET_LIST", {
          onData: this.onDataSearch,
          search: { term: value }
        });
      }
    },
    onConversationClickedItem(conversation) {
      this.currentConversationId = conversation._id;

      this.$nextTick(() => {
        this.onConversationClicked(conversation);
      });
    },
    onData(data) {
      this.conversations = data;
      this.$nextTick(() => {
        EventBus.$emit("No_Patient_Messanger");
      });

      this.loader = false;
      this.$forceUpdate();
    },
    onDataSearch({ ok, data }) {
      if (ok) {
        this.searchData = data;
      }
    },
    chatByChange(value) {
      this.$emit("update:chatBy", value);
    },
    moment() {
      return moment;
    }
  }
};
</script>
